import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'
import { LoginCallback } from '@okta/okta-vue'

const oktaAuth = new OktaAuth({
  issuer: 'https://afterpaytouch.okta.com',
  clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email']
})

Vue.use(VueRouter)
Vue.use(OktaVue, { oktaAuth })

const routes = [
  { path: '/login/callback',
    name: 'LoginCallback',
    component: LoginCallback
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
