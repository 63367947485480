<template>
  <v-container
    class="d-flex flex-column"
    fluid
  >
    <v-row >
      <v-img :src="require('../assets/afterpay-banner.png')">
      </v-img>
    </v-row>
      <v-row class="d-flex justify-space-around mt-n12" >
        <v-col sm="4" md="3" lg="3" v-for="image in images" :key="image.name" >
          <a :href="image.url" target="_blank">
          <v-img
              :src="image.path"
               :height=height
              contain
            ></v-img>
            </a>
        </v-col>
      </v-row>
    <v-footer padless app >
    <v-col
      class="text-center text-h6 pa-0"
      style="color: gray"
    >
      For further question, contact <a v-bind:href="'mailto:' + email" class="text-decoration-underline" style="color: gray">{{ email }}</a>
    </v-col>
  </v-footer>
  </v-container>
</template>

<script>
export default {
  name: "report_home",
  data(){
    return{
      images:[
        {path: require('../assets/image with frame and text/Global l1.png'),
          name:"Global L1 KPI", url:'https://tableau.corp.afterpay.com/#/projects/185'},
        {path: require('../assets/image with frame and text/business key report.png'),
          name:"Key Reports", url:'https://tableau.corp.afterpay.com/#/projects/135'},
      {path: require('../assets/image with frame and text/Tableau dashboard.png'),
          name:"Tableau Dashboard & Tutorial Portal", url:'https://defamibxuy2ya.cloudfront.net/'}
          ,{path: require('../assets/image with frame and text/Tableau report directory.png'),
          name:"Tableau Report Directory", url:'https://defamibxuy2ya.cloudfront.net/mindmap'}
      ],
      email:'bi@afterpay.com'
    }
  },
  computed: {
    height :function () {
        switch (this.$vuetify.breakpoint.name) {
          // case 'xs': return 220
          // case 'sm': return 400
          // case 'md': return 500
          // case 'lg': return 500
          case 'xl': return 650
          default: return 425
        }
      },
  }

}

</script>


<style scoped>
*{ text-transform: none !important; }
</style>
